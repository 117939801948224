import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

// components
import Page from "../utils/page";
import Hero from "../utils/hero";
import theme from "../utils/theme";
import CampioneGratuitoForm from "../components/campione-gratuito-form";

// markup
const CampionePage = () => {
    return (
        <Page
            title="Campione gratuito salviette monouso e bavagli"
            description="Prova le nostre salviette umidificate monouso ed i nostri bavagli in tnt monouso. Il tuo kit contiene: 8 salviette (1 per ogni fragranza), 4 bavagli (1 per ogni formato)."
            type="ContactPage"
            image="/richiedi-campione-gratuito.jpg"
        >
            <Hero
                title="Campione gratuito"
                description={
                    <>
                        Prova le nostre <strong>salviette umidificate monouso</strong> ed i nostri <strong>bavagli in tnt</strong> monouso.
                    </>
                }
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/backgrounds/fragranza-menta.jpg"
                        alt="Fragranza menta"
                        layout="fullWidth"
                    />
                }
            />

            <CampioneGratuitoForm />
        </Page>
    );
};

export default CampionePage;
