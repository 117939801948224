import * as React from "react";
import {
    Grid,
    Alert,
    Box,
    Typography,
    Button,
    Stack,
    AlertTitle,
    TextField,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import { StaticImage } from "gatsby-plugin-image";
import * as yup from "yup";

// component
import { useFirebase } from "../utils/useFirebase";
import theme from "../utils/theme";
import Link from "../utils/link";
import Section from "../utils/section";

const validationSchema = yup.object({
    name: yup.string().required(),
    piva: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    address: yup.string().required(),
    note: yup.string(),
    privacy: yup.number().oneOf([1]),
});

const CampioneGratuitoForm = () => {
    const [isSent, setIsSent] = React.useState(false);
    const { firestore } = useFirebase();

    const formik = useFormik({
        initialValues: {
            name: "",
            piva: "",
            phone: "",
            email: "",
            address: "",
            note: "",
            privacy: 0,
            marketing: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (firestore) {
                const db = firestore.getFirestore();
                firestore
                    .addDoc(firestore.collection(db, "contacts"), {
                        to: [values.email],
                        bcc: ["info@mettifogo.eu"],
                        message: {
                            subject: `Campione gratuito per ${values.name} - Mettifogo S.r.l`,
                            text: `
                            Gentile ${values.name}, saremo felici di inviarLe il nostro campione all'indirizzo ${
                                values.address
                            } - Partiva iva: ${
                                values.piva
                            }. Per eventuali comunicazioni potrà essere contattato via telefono al numero ${
                                values.phone
                            } o al suo indirizzo email ${values.phone}. Note: ${
                                values.note || "-"
                            }. Team Mettifogo S.r.l`,
                            html: `
                            <p>Gentile ${values.name},<br/>
                            saremo felici di inviarLe il nostro campione all'indirizzo: <strong>${
                                values.address
                            }</strong> - Partiva iva: <strong>${values.piva}</strong>.</p>
                            <p>Per eventuali comunicazioni potrà essere contattato via telefono al numero <strong>${
                                values.phone
                            }</strong> o al suo indirizzo email <strong>${values.email}</strong>.</p>
                            <p>Note: <br/>${values.note || "-"}</p>
                            <p><i>Team Mettifogo S.r.l</i></p>
                        `,
                        },
                    })
                    .then(() => {
                        setIsSent(true);
                        resetForm();
                    });
            }
        },
    });

    return (
        <Section>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <Typography variant="h3" component="h2">
                            Sei incuriosito dai nostri prodotti?
                        </Typography>
                        <Typography>
                            Compila i campi qui sotto e riceverai all'indirizzo desiderato{" "}
                            <strong>senza alcun costo</strong> una campionatura completa.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack spacing={1}>
                        {/* <Box pb={4}>
                            <StaticImage
                                style={{ height: "100%" }}
                                quality={100}
                                src="../images/kit-campione-gratuito.jpg"
                                alt="Kit campione gratuito"
                                layout="fullWidth"
                            />
                        </Box> */}
                        <Typography>Il tuo kit contiene:</Typography>
                        <ul>
                            <li>8 salviette, 1 per ogni fragranza</li>
                            <li>4 bavagli, 1 per ogni formato</li>
                        </ul>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box>
                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                />
                                <TextField
                                    fullWidth
                                    label="Telefono"
                                    variant="outlined"
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                />
                                <TextField
                                    fullWidth
                                    label="Indirizzo"
                                    variant="outlined"
                                    id="address"
                                    name="address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    error={formik.touched.address && Boolean(formik.errors.address)}
                                />
                                <TextField
                                    fullWidth
                                    label="Partita iva"
                                    variant="outlined"
                                    id="piva"
                                    name="piva"
                                    value={formik.values.piva}
                                    onChange={formik.handleChange}
                                    error={formik.touched.piva && Boolean(formik.errors.piva)}
                                />

                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Note"
                                    variant="outlined"
                                    id="note"
                                    name="note"
                                    value={formik.values.note}
                                    onChange={formik.handleChange}
                                    error={formik.touched.note && Boolean(formik.errors.note)}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="privacy"
                                            name="privacy"
                                            value={1}
                                            onChange={formik.handleChange}
                                        />
                                    }
                                    label={
                                        <>
                                            Ho letto e accetto la{" "}
                                            <Link
                                                title="Privacy policy"
                                                type="external"
                                                href="https://www.iubenda.com/privacy-policy/97449482"
                                                underline="always"
                                            >
                                                privacy policy
                                            </Link>
                                        </>
                                    }
                                    style={{
                                        color:
                                            formik.touched.privacy && Boolean(formik.errors.privacy)
                                                ? theme.palette.error.main
                                                : theme.palette.text.primary,
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="marketing"
                                            name="marketing"
                                            value={1}
                                            onChange={formik.handleChange}
                                        />
                                    }
                                    label=" Do il consenso al trattamento dei miei dati per fini commerciali"
                                    style={{
                                        color:
                                            formik.touched.marketing && Boolean(formik.errors.marketing)
                                                ? theme.palette.error.main
                                                : theme.palette.text.primary,
                                    }}
                                />
                                <Box justifyContent="flex-end" display="flex">
                                    <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                                        richiedi campione gratuito
                                    </Button>
                                </Box>
                            </Stack>
                        </form>

                        {isSent && (
                            <Box mt={4}>
                                <Alert severity="success">
                                    <AlertTitle>Grazie per aver richiesto il campione gratuito.</AlertTitle>
                                    Sarà contattato a breve da un nostro operatore per ricevere il Suo campione
                                    gratuito.
                                </Alert>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Section>
    );
};

export default CampioneGratuitoForm;
